import React, { FC, useEffect, useState, useRef } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from "axios";
import { API_URL } from "../../api/config";
import { toast } from "react-toastify";
export interface PageAddListing1Props {
  formik: any;
}

const PageAddListing1: FC<PageAddListing1Props> = ({ formik }) => {
  const [selectedType, setSelectedType] = useState("Homestays");
  const [selectedSubType, setSelectedSubType] = useState("");
  const [otp, setOtp] = useState(Array(4).fill(""));
  const [isOtpSent, setIsOtpSent] = useState(false); 
  const firstInputRef = useRef<HTMLInputElement>(null);

  const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedType(event.target.value);
    formik.handleChange(event);
  };
  const handleSubTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSubType(event.target.value);
    formik.handleChange(event);
  };
  const getDescription = (selectedType: string) => {
    switch (selectedType) {
      case "Homestays":
        return "Homestays: A luxurious residence, often with expansive grounds, offering comfort, privacy, and upscale amenities.";
      default:
        return "A catchy name usually includes: House name + Room name + Featured property + Tourist destination";
    }
  };
  const renderSubtype = (selectedType: string) => {
    switch (selectedType) {
      case "Homestays":
        return ["Entire home unit", "Shared apartments"];
      case "Pgs":
        return "";
      default:
        return [];
    }
  };
  const subtypes = renderSubtype(selectedType);

  useEffect(() => {
    if (subtypes.length > 0) {
      formik.setFieldValue("subtype", subtypes[0]);
    }
  }, []);

  const handleToggleType = (option: any) => {
    formik.setFieldValue("type", option);
    setSelectedType(option);
  };
  const handleToggleSubType = (option: any) => {
    formik.setFieldValue("subtype", option);
  };



  const handleOtpChange = (value: any, index: any) => {
    if (!/^\d*$/.test(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    
  // Update Formik's otp value with the joined OTP digits
    formik.setFieldValue("otp", newOtp.join(""));
    // Focus the next input
    if (value && index < 3) {
      document.getElementById(`otp-input-${index + 1}`)?.focus();
    }
  };
 
  const handleGetOtp = async () => {
    const phoneNumber = formik.values.phoneNumber;

    if (!phoneNumber) {
      toast.error("Please enter a valid phone number.");
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/property/request-otp`, { phoneNumber });
      if (response.data.error === false) {
        toast.success("OTP sent successfully.");
        setIsOtpSent(true);
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message || "Failed to send OTP.");
    }
  };

  const handleVerifyOtp = async () => {
    const phoneNumber = formik.values.phoneNumber;
    const otpValue = otp.join("");

    if (otpValue.length !== 4) {
      toast.error("Please enter the complete OTP.");
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/property/request-otp`, {
        phoneNumber,
        otp: otpValue,
      });

      if (response.data.error ===  true) {
        toast.error(response.data.message);
      } else {
        toast.success("OTP verified successfully.");
        console.log("Token:", response.data.token); 
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message || "OTP verification failed.");
    }
  };


  return (
    <CommonLayout
      index="01"
      backtHref="/add-listing-1"
      nextHref="/add-listing-2"
    >
      <>
      <h2 className="text-2xl font-semibold">Choosing listing categories</h2>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="space-y-8">
          

          <FormItem>
            <Input
              className="!sm:w-full md:w-4/6 text-neutral-900"
              placeholder="Name your property"
              {...formik.getFieldProps("title")}
            />
            {formik.errors && formik.errors.title && (
              <span className="text-red-500 text-sm">
                {formik.errors.title}
              </span>
            )}
          </FormItem>

          <FormItem>
            <div className="flex">
              <Input
                type="tel"
                className="sm:!w-4/6 !text-neutral-900"
                placeholder="Assign phone number"
                {...formik.getFieldProps("phoneNumber")}
                onInput={(e) => {
                  const input = e.target as HTMLInputElement;
                  input.value = input.value.replace(/\D/g, "");
                }}
                maxLength={10}

              />
              <ButtonPrimary
                className="rounded-none ml-2"
                onClick={handleGetOtp}
              >
                {isOtpSent ? "Resend OTP" : "Get OTP"}
              </ButtonPrimary>
            </div>

            {formik.errors && formik.errors.phoneNumber && (
              <span className="text-red-500 text-sm">
                {formik.errors.phoneNumber}
              </span>
            )}
          </FormItem>
          <div className="flex space-x-2  mt-2 w-3/6">
            {otp.map((digit, index) => (
              <Input
                name="otp"
                key={index}
                id={`otp-input-${index}`}
                type="tel"
                ref={index === 0 ? firstInputRef : undefined}
                className="!w-14 h-14 text-center text-neutral-600"
                value={digit}
                onChange={(e) => handleOtpChange(e.target.value, index)}
                maxLength={1}
              />
            ))}
          </div>
          <ButtonPrimary onClick={handleVerifyOtp} disabled={!isOtpSent} className={`${isOtpSent ? "visible" : "invisible"}`}>
          Verify OTP
        </ButtonPrimary>
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing1;
