import Label from "components/Label/Label";
import React, { FC } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { useState, useRef } from "react";
import GoogleMapReact from "google-map-react";
import LocationMarker from "components/AnyReactComponent/LocationMarker";
import axios from "axios";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface PageAddListing2Props {
  formik: any;
}

const PageAddListing2: FC<PageAddListing2Props> = ({ formik }) => {
  const [selectedType, setSelectedType] = useState("Homestays");
  const [mapCenter, setMapCenter] = useState({
    lat: 19.220994303357923,
    lng: 72.98498193443554,
  });
  const [markerPosition, setMarkerPosition] = useState({
    lat: 19.220994303357923,
    lng: 72.98498193443554,
  });
  const mapRef = useRef<google.maps.Map | null>(null);
  const markerRef = useRef<google.maps.Marker | null>(null);

  const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedType(event.target.value);
    formik.handleChange(event);
  };

  const handleMarkOnMap = async () => {
    const address = formik.values.Address;
    const geocodingAPI = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=AIzaSyDE4YXJP-Oq9uXDySWbJBOBtwej5bdrw1A`;

    try {
      const response = await axios(geocodingAPI);
      console.log("response of google map", response);

      if (response.data.status === "OK") {
        const { lat, lng } = response.data.results[0].geometry.location;
        console.log("lat and lng", lat, lng);

        setMapCenter({ lat, lng });
        setMarkerPosition({ lat, lng });
        formik.setFieldValue("latitude", lat);
        formik.setFieldValue("longitude", lng);
        if (mapRef.current) {
          mapRef.current.panTo({ lat, lng });
        }
        if (markerRef.current) {
          markerRef.current.setPosition({ lat, lng });
        }
      } else {
        alert("Address not found, please try another address.");
      }
    } catch (error) {
      console.error("Error fetching geocode data:", error);
      alert("An error occurred. Please try again.");
    }
  };


  const handleApiLoaded = ({ map, maps }: { map: google.maps.Map; maps: any }) => {
    mapRef.current = map;
    markerRef.current = new maps.Marker({
      position: markerPosition,
      map,
      draggable: true,
    });

    markerRef.current?.addListener("dragend", async (event: google.maps.MapMouseEvent) => {
      const lat = event.latLng?.lat();
      const lng = event.latLng?.lng();

      if (lat && lng) {
        setMarkerPosition({ lat, lng });
        formik.setFieldValue("latitude", lat);
        formik.setFieldValue("longitude", lng);

        const geocodingAPI = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDE4YXJP-Oq9uXDySWbJBOBtwej5bdrw1A`;

        try {
          const response = await axios(geocodingAPI);
          if (response.data.status === "OK") {
            const address = response.data.results[0].formatted_address;
            formik.setFieldValue("Address", address);
          } else {
            alert("Failed to fetch address for the new location.");
          }
        } catch (error) {
          console.error("Error reverse geocoding:", error);
        }
      }
    });
  }

  return (
    <CommonLayout
      index="02"
      nextHref="/add-listing-3"
      backtHref="/add-listing-1"
    >
      <>
        <h2 className="text-2xl font-semibold">Your place location</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* label="Country/Region" */}
          <FormItem className="focus-ring">
            <Select
              {...formik.getFieldProps("category")}
              className="!w-3/6 rounded-none"
            >
              <option value="category">category</option>
              <option value="Flat">Flat</option>
              <option value="Bungalow">Bungalow</option>
              <option value="Farm House">Farm House</option>
              <option value="Cottage">Cottage</option>
              {/* <option value="Singapore">Singapore</option>
              <option value="Jappan">Jappan</option>
              <option value="Korea">Korea</option> */}
            </Select>
            {formik.errors && formik.errors.category && (
              <span className="text-red-500 text-sm">
                {formik.errors.category}
              </span>
            )}
          </FormItem>

          {/* <FormItem label="Name of the Area">
            <Input placeholder="..." {...formik.getFieldProps("area_name")} />
            {formik.errors && formik.errors.area_name && (
              <span className="text-red-500 text-sm">
                {formik.errors.area_name}
              </span>
            )}
          </FormItem> */}
          <FormItem>
            <Select
              {...formik.getFieldProps("type")}
              onChange={handleTypeChange}
              className="!w-3/6 rounded-none"
            >
              <option value="Type of Place">Type of Place</option>

              <option value="Entire Property">Entire Property</option>
              <option value="A Room">A Room</option>
            </Select>
            {formik.errors && formik.errors.type && (
              <span className="text-red-500 text-sm">{formik.errors.type}</span>
            )}
          </FormItem>

          {/* Address */}
          <FormItem>
            <div className="flex">
              <Input
                placeholder="Address"
                {...formik.getFieldProps("Address")}
                className="w-7/12"
              />
              <button
                className={`hover-btn ml-2 px-4 py-1 sm:text-xs border border-neutral-200 text-neutral-50 ttnc-ButtonPrimary ${
                  !formik.values.Address
                    ? "cursor-not-allowed bg-gray-400"
                    : "bg-primary-6000 hover:bg-primary-700"
                }`}
                onClick={handleMarkOnMap}
                style={{ backgroundColor: "#022A60" }}
              >
                Mark on the map
              </button>
            </div>

            {formik.errors && formik.errors.landmark && (
              <span className="text-red-500 text-sm">
                {formik.errors.Address}
              </span>
            )}
          </FormItem>
          {/* Google Map */}

          <div className="mt-4">
            <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
              <div className="rounded-xl overflow-hidden">
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyDE4YXJP-Oq9uXDySWbJBOBtwej5bdrw1A",
                  }}
                  defaultZoom={15}
                  // center={{ lat: 19.05676215343939, lng: 73.00357306289678 }}
                  center={mapCenter}
                  // onGoogleApiLoaded={({ map }) => {
                  //   mapRef.current = map; // Store the map instance
                  // }}
                  onGoogleApiLoaded={handleApiLoaded}
                  yesIWantToUseGoogleMapApiInternals
                >
                  {/* <LocationMarker
                    lat={19.05676215343939}
                    lng={73.00357306289678}
                  /> */}
                  {/* <LocationMarker
                    lat={markerPosition.lat}
                    lng={markerPosition.lng}
                  /> */}
                </GoogleMapReact>
              </div>
            </div>
          </div>

          <div className="flex w-full">
            {/* Added landmark field */}
            <FormItem className="w-6/12">
              <Input
                className=""
                placeholder="Nearest Landmark"
                {...formik.getFieldProps("landmark")}
              />
              {formik.errors && formik.errors.landmark && (
                <span className="text-red-500 text-sm">
                  {formik.errors.landmark}
                </span>
              )}
            </FormItem>
            {/* Added Airport field */}

            <FormItem className="w-6/12">
              <Input
                placeholder="Nearest Airport"
                className=" ml-2"
                {...formik.getFieldProps("nearest_airport")}
              />
              {formik.errors && formik.errors.nearest_airport && (
                <span className="text-red-500 text-sm">
                  {formik.errors.nearest_airport}
                </span>
              )}
            </FormItem>
          </div>

          {/* <FormItem label="City">
              <Input {...formik.getFieldProps("city")} />
              {formik.errors && formik.errors.city && (
                <span className="text-red-500 text-sm">
                  {formik.errors.city}
                </span>
              )}
            </FormItem> */}

          {/* <div>
            <Label>Detailed address</Label>
            <span className="block mt-1 text-sm text-neutral-500 dark:text-neutral-400">
              {`${floor ? `floor no-${floor},` : ""}  ${
                area_name ? `${area_name},` : ""
              } ${landmark ? `${landmark},` : ""} ${city ? `${city},` : ""} ${
                country ? `${country}` : ""
              } ${" "}`}
            </span>
          </div> */}
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing2;
