import React, { FC } from "react";
import CommonLayout from "./CommonLayout";
import { title } from "process";
import barbar from "../../images/Amenities/barbar.svg";
import fingerprint from "../../images/Amenities/fingerprint.svg";
import monitor from "../../images/Amenities/monitor.svg";
import cooking from "../../images/Amenities/cooking.svg";
import Towel from "../../images/Amenities/Towel.svg";
import towel2 from "../../images/Amenities/towel2.svg";
import Butler from "../../images/Amenities/Butler.svg";
import doublebed from "../../images/Amenities/doublebed.svg";
import fridge from "../../images/Amenities/fridge.svg";
import Hall from "../../images/Amenities/Hall.svg";
import hanger from "../../images/Amenities/hanger.svg";
import induction from "../../images/Amenities/induction.svg";
import iron from "../../images/Amenities/iron.svg";
import Kitchen from "../../images/Amenities/Kitchen.svg";
import laundry from "../../images/Amenities/laundry.svg"; 
import microwave from "../../images/Amenities/microwave.svg";
import mineralwater from "../../images/Amenities/mineralwater.svg";
import mosquitoRepellent from "../../images/Amenities/mosquitoRepellent.svg";
import raindrops from "../../images/Amenities/raindrops.svg";
import Shampoo from "../../images/Amenities/Shampoo.svg";
import Shower from "../../images/Amenities/Shower.svg";
import snowflake from "../../images/Amenities/snowflake.svg";
import utensils from "../../images/Amenities/utensils.svg";
import washingMachine from "../../images/Amenities/washingMachine.svg";
import waterheater from "../../images/Amenities/waterheater.svg";
import waterpurifier from "../../images/Amenities/waterpurifier.svg";
import watertank from "../../images/Amenities/watertank.svg";
import wifi from "../../images/Amenities/wifi.svg";
import gasstove from "../../images/Amenities/gasstove.svg";
import scenicviews from "../../images/Amenities/scenicviews.svg";
import wardrobe from "../../images/Amenities/wardrobe.svg";
import jetspray from "../../images/Amenities/jetspray.svg";










export interface PageAddListing4Props {
  formik: any;
}

const PageAddListing4: FC<PageAddListing4Props> = ({ formik }) => {
  
  const amenitiesList = [
    { "title": "24/7 Water Supply", "icon": raindrops },
    { "title": "Balcony", "icon": Hall },
    { "title": "Bedsheets", "icon": doublebed },
    { "title": "Blankets", "icon": Towel },
    { "title": "Clothes Hanger", "icon": hanger },
    { "title": "EV Charging", "icon": waterpurifier },
    { "title": "Fridge", "icon": fridge },
    { "title": "Gas Stove", "icon": gasstove },
    { "title": "HouseKeeping Service", "icon": Butler },
    { "title": "Induction", "icon": cooking },
    { "title": "Iron", "icon": iron },
    { "title": "Jet Spray", "icon": jetspray },
    { "title": "Laundry Service", "icon": laundry },
    { "title": "Microwave", "icon": microwave },
    { "title": "Mineral Water", "icon": mineralwater },
    { "title": "Mosquito repellent", "icon": mosquitoRepellent },
    { "title": "Oven", "icon": Kitchen },
    { "title": "Room Freshner", "icon": snowflake },
    { "title": "Scenic Views", "icon": scenicviews },
    { "title": "Self Checkin", "icon": fingerprint },
    { "title": "Showers", "icon": Shower },
    { "title": "Smart TV", "icon": monitor },
    { "title": "Toiletries", "icon": barbar },
    { "title": "Towels", "icon": towel2 },
    { "title": "Utensils", "icon": utensils },
    { "title": "Wardrobe", "icon": wardrobe },
    { "title": "Washing Machine", "icon": washingMachine },
    { "title": "Water Filter", "icon": waterpurifier },
    { "title": "Water Heater", "icon": waterheater },
    { "title": "Water Storage Tank", "icon": watertank },
    { "title": "Wifi", "icon": wifi }
  ];
  
  
  


  const handleToggleAmenity = (amenity:any) => {
    const currentAmenities = formik.values.amenities;
    if (currentAmenities.includes(amenity.title)) {
      formik.setFieldValue(
        "amenities",
        currentAmenities.filter((item:any) => item !== amenity.title)
      );
    } else {
      formik.setFieldValue("amenities", [...currentAmenities, amenity.title]);
    }
  };

  return (
    <CommonLayout
    index="04"
    backtHref="/add-listing-3"
    nextHref="/add-listing-5"
  >
    {/* <div className="nc-PageAddListing1 px-4 max-w-3xl mx-auto pb-4 pt-14 sm:py-24 lg:pb-2"> */}
  
      
      {/* <div className="listingSection__wrap "> */}
      <div>
          <h2 className="text-2xl font-semibold">Amenities </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Many customers have searched for accommodation based on amenities
            criteria
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-8"></div>
        <div className="flex flex-row flex-wrap ">
        {amenitiesList.map((amenity) => (
          // <button
          //   key={amenity.title}
            
          //   style={{border:formik.values.amenities.includes(amenity.title) ? "1px solid red":"1px solid grey"}}
          //   type="button"
          //   onClick={() => handleToggleAmenity(amenity)}
          //   className={`flex items-center justify-center px-4 py-2 border rounded-full text-sm font-medium mt-2 mr-2
          
          //    hover:bg-gray-100`}
          // >
          //   <span>
          //     <img src={amenity.icon} alt="icon" className="mr-2"/>
          //   </span>
          //   {amenity.title}
          // </button>
          <button
  key={amenity.title}
  type="button"
  onClick={() => handleToggleAmenity(amenity)}
  className={`flex items-center justify-center px-4 py-2 rounded-full text-sm font-medium mt-2 mr-2
     
    ${formik.values.amenities.includes(amenity.title) 
      ? "gradient-border"
      : "border border-gray-300 hover:bg-gray-100"} 
  `}
>
  <span className="flex items-center">
    <img src={amenity.icon} alt="icon" className="mr-2" />
    {amenity.title}
  </span>
</button>
        
        ))}
        </div>
     
  
      
      {/* </div> */}
    {/* </div> */}
    </CommonLayout>
  );
}


// border-2 border-indigo-500/100

export default PageAddListing4;


  //    ${
            //   formik.values.amenities.includes(amenity.title)
            //     ? "gradient-border "
            //     : "bg-white text-gray-700 border-gray-300"
            // }